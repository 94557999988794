/** @jsx jsx */
import { jsx } from "theme-ui";

import Layout from "../components/PageLayout";

const NotFoundPage = () => {
  return (
    <Layout>
      <div
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <div
          sx={
            {
              //background: "red"
            }
          }
        >
          <h1 sx={{ variant: "h1.primary" }}>Page Not Found :(</h1>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
